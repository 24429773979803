<template>
	<div class="index">
		
		
		<div class="index2" >
			<div class="re_from">
				<!-- 列表 UserInfo -->
				<div style="text-align: center;margin: 10px;">总览<span style="color:#9EA3A7 ;font-size: 10px;margin-left: 10px;">(每小时更新一次)</span></div>
				
				<div class="table4-1" >
					<div style="display: flex;justify-content: flex-start;margin: 5px;padding:5px; border-bottom: 1px solid #343B44;font-size: 12px;">
						<div style="width: 33.3%;">初始本金</div>
						<div style="width: 33.3%;">盈利</div>
						<div style="width: 33.3%;">年回报</div>
						
						
					</div>
					<div style="display: flex;justify-content: flex-start;margin: 5px;padding:5px; border-bottom: 1px solid #343B44;font-size: 12px;">
						<div style="color: #9EA3A7;width: 33.3%;">{{UserInfo.start_money}}</div>
						
						<div style="color: #55CDEB;width: 33.3%;"><span v-if="UserInfo.fuying > 0 ">+</span>{{UserInfo.fuying}}</div>
						<div style="color: #55CDEB;width: 33.3%;">{{UserInfo.nhb}}%</div>
						
					</div>
					<div style="display: flex;justify-content: flex-start;margin: 5px;padding:5px; border-bottom: 1px solid #343B44;font-size: 12px;">
						<div style="width: 33.3%;">现有资金</div>
						<div style="width: 33.3%;">服务费</div>
						<div style="width: 33.3%;">开始时间</div>
					</div>
					<div style="display: flex;justify-content: flex-start;margin: 5px;padding:5px; font-size: 12px;">
						<div style="color: #C4983D;width: 33.3%;">{{UserInfo.money}}</div>
						<div style="width: 33.3%;"><span style="color: #C4983D;">{{UserInfo.commission_charge}}</span>/<span style="color: #9EA3A7;">{{UserInfo.commission_charge2}}</span></div>
						<div style="color: #9EA3A7;width: 33.3%;">{{UserInfo.start_time}}</div>
					</div>
				</div>
				
				
				
				
				<div style="text-align: center;margin: 10px;">图表<span style="color:#9EA3A7 ;font-size: 10px;margin-left: 10px;">(每天0点更新)</span></div>
				<div class="table1">
					<div style="width:100%;" v-if="showchart">
						<v-chart  :option="option_column" theme="dark-fresh-cut" :loading="loading" style="height: 200px" ref="ref_echart_index"></v-chart>			
					</div>
				</div>
				<!-- <div class="table2">
					<div class="item">
						<div>111</div>
						<div>222</div>
						<div>333</div>
					</div>
					<div class="item"></div>
					<div class="item"></div>
					<div class="item"></div>
				</div> -->
				
				<!-- <div class="table3">
					<div class="btn1"><span>按钮1</span></div>
					<div class="btn2"><span>按钮2</span></div>
				</div> -->
				
				
				
				<div style="text-align: center;margin: 10px;">每日记录<span style="color:#9EA3A7 ;font-size: 10px;margin-left: 10px;">(每天0点更新)</span></div>
				<div class="table4" >
					<div style="display: flex;justify-content: flex-start;margin: 5px;padding:5px; border-bottom: 2px solid #343B44;font-size: 12px;">
						<div style="width: 25%;">余额</div>
						<div style="width: 25%;">收益</div>
						<!-- <div>年化</div> -->
						<div style="width: 25%;">服务费</div>
						<!-- <div>折扣</div> -->
						<div style="width: 25%;">时间</div>
					</div>
					<div v-for="(item,index) in balanceLogList" style="display: flex;justify-content: flex-start;margin: 5px;padding:5px; border-bottom: 2px solid #343B44;font-size: 12px;">
						<div style="color: #C4983D;width: 25%;">{{item.money}}</div>
						<div style="color: #55CDEB;width: 25%;">{{item.amount}}</div>
						<!-- <div style="color: #C4983D;">{{item.apr}}%</div> -->
						<div style="color: #9EA3A7;width: 25%;">{{item.fee}}</div>
						<!-- <div style="color: #9EA3A7;">{{item.discount_fee}}</div> -->
						<div style="color: #9EA3A7;width: 25%;">{{item.create_time }}</div>

						
					</div>
				</div>
				
				<div style="display: flex;justify-content: center;">
					
					<div class="btn_medium_1" @click="to_depositlist()"><span>充值记录</span></div>
					<!-- <button class="btn_blue_class" style="margin: 10px 0;max-width: 500px;" @click="to_depositlist()"><span>充值记录</span></button> -->
				</div>
				
			</div>
			
		</div>
		
		
	</div>
</template>

<script>
	import request from '@/utils/request'
	import {formatDate} from '@/utils/relUrl'
	export default {
		name: 'index',
		data() {
			return {
				layout: {
					bannerHeight: 300,
					activityHeight: 250,
				},
				page:1,
				game_type:2,
				n:0,
				show:true,
				gameactive:2,
				scrollTop:0,
				
				showgameshide:false,
				btn1:true,
				btn2:false,
				showscroll:false,

				showchart:false,
				loading:false,
				option_column: {
					title: {text: ""},
					tooltip: {trigger: 'axis'},
					legend: {
					    data: ['收益']
					},
					xAxis: {data: ["", "", "", "", "", ""],},
					yAxis: {type: 'value'},
					series: [
						{
							name: "收益",
							type: "line",
							stack: 'Total',
							smooth: true,
							data: [0, 0, 0, 0, 0, 0],
						}, 
					],
				},
				
				UserInfo:{},//我的资金
				showlogin:false,
				user_code:'',//invite_code
				balanceLogList:[],
			};
		},
		activated() {
		    //console.log('我这个页面显示就会执行');
			let _this = this;
			//刷新
			if(_this.$route.params.refresh){
				//_this.$router.go(0)
				location.reload();
			}

			
		},
		mounted() {
			let _this = this;
			this.local_data();
			//this.imgLoad();
			//window.addEventListener("resize", () => this.$refs?.ref_echart_index?.resize());
			
		},
		filters:{
			formatDate (time){

				let date=new Date(time);
				return formatDate(date,'yyyy-MM-dd hh:mm');
			}
		},

		methods: {

			local_data(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				if (token == null || token == '' || token == 'null' || token == undefined || token == 'undefined') {
					this.hasLogin = 0;
					window.sessionStorage.clear();
					_this.$router.replace({ name: 'Login', params: { refresh: true }})
				}else{
					this.hasLogin = 1;
					_this.showlogin = true;
					//_this.getuser();
					this.balanceLog();

				}
				
	
			},
			
			
			//获取用户信息
			getuser(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				request.post('finance/user/index', {
					token: token,
				}).then(res => {
					//console.log(res)
					//结果
					if(res.data.code ==0){
						window.sessionStorage.setItem('user_info',JSON.stringify(res.data.data));
					}else{
						
						_this.$message({
						    message: res.data.msg,
						    type: 'warning'
						});
					}
				});
			},
			
			imgLoad() {
				this.$nextTick(() => {
					let ref = this.$refs
					//console.log(ref)
					this.layout.bannerHeight = ref.bannerHeight && ref.bannerHeight.length ? ref.bannerHeight[0]
						.height : '';
					this.layout.activityHeight = ref.activityHeight && ref.activityHeight.length ? ref
						.activityHeight[0].height : '';
				})
				
			},
			
			//用户收入记录 https://api.blockmoneychain.com/finance/user/balanceLog
			balanceLog(){
				let _this = this;
				this.loading = true
				let token = window.sessionStorage.getItem('token');
				request.post('finance/user/balanceLog', {
					token: token,
				}).then(res => {
					//console.log(res)
					//结果
					if(res.data.code ==0){
						this.balanceLogList = res.data.data.list.reverse();//倒序
						this.UserInfo = res.data.data.user[0];
						//图表
						this.showchart = true;
						let balanceList = res.data.data.list;
						let datetime = [];
						let profit = [];
						let commision = [];
						let apr = [];
						for(let i=0;i<balanceList.length;i++){
							datetime.push(balanceList[i].create_time);
							profit.push(balanceList[i].profit);
							// commision.push(balanceList[i].commision);
							apr.push(balanceList[i].profit);
						}
						_this.option_column.title.text = '';
						_this.option_column.xAxis.data = datetime.reverse();//倒序
						_this.option_column.series[0].name = '收益';
						_this.option_column.series[0].data = apr.reverse();//倒序
					}else{
						
						_this.$message({
						    message: res.data.msg,
						    type: 'warning'
						});
					}
					this.loading = false;
				});
			},
			
			//跳转充值记录
			to_depositlist(){
				this.$router.replace({ name: 'DepositList', params: { refresh: true }})
			},
			
			
			
			
			
			
			
			
			
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

	.index{
		
		background:url(../assets/images/blockmoney/bg.png);
		background-size: cover;
		background-position: 50%;
		height: calc(100vh - 60px);
		/* height: auto; */
		display: flex;
		justify-content: center;
		/* align-items: center; */
		max-width: 640px;
		text-align: center;
		margin: 0 auto;
	}
	.index2{
		border: 2px solid #3B6DB2;
		border-radius: 5px;
		background: linear-gradient(180deg, #0C4781, #051022 5%);
		padding: 10px;
		width: 90%;
		max-width: 640px;
		height: calc(100vh - 90px);
		margin-top: 20px;
		margin-bottom: 20px;
		overflow: auto;

		
	}
	.re_from{
		
		
	}
	.table1{
		width: 100%;
		min-height: 200px;
		background-color: #051022;
		border: 2px solid #343B44;
	}
	.table2{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
	}
	.item{
		width: calc(25% - 5px );
		height: 100px;
		background-color: #051022;
		border: 2px solid #343B44;
		/* display: flex;
		flex-direction:column;
		justify-content: space-between; */
	}
	.table3{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
	}
	.btn1{
		width: calc(50% - 5px);
		height: 50px;
		background:url(../assets/images/blockmoney/btn_mini_1.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.btn2{
		width: calc(50% - 5px);
		height: 50px;
		background:url(../assets/images/blockmoney/btn_mini_2.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.btn_medium_1{
		width: calc(100% - 5px);
		height: 55px;
		background:url(../assets/images/blockmoney/btn_medium_1.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		margin: 10px 0;
		max-width: 600px;
	}
	.table4{
		width: 100%;
		min-height: 200px;
		background-color: #051022;
		border: 2px solid #343B44;
		margin-top: 10px;
	}
	.table4-1{
		width: 100%;
		min-height: 100px;
		background-color: #051022;
		border: 2px solid #343B44;
		margin-top: 10px;
	}
	.btn_blue_class {
		-webkit-box-align: center;
		align-items: center;
		border: 0px;
		border-radius: 5px;
		box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
		cursor: pointer;
		display: inline-flex;
		font-family: inherit;
		font-size: 14px;
		font-weight: 600;
		-webkit-box-pack: center;
		justify-content: center;
		letter-spacing: 0.03em;
		line-height: 1;
		opacity: 1;
		outline: 0px;
		transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
		height: 36px;
		padding: 0px 16px;
		background-color: #015DB4;
		color: white;
		width: 100%;
		
	}
	
	
	
</style>
